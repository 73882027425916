const services = {
  scarab: {
    cookies: ['scarab.visitor']
  },
  matomo: {
    cookies: []
  }
};

// this could be set for every service as default in the object directly
Object.values(services).map(service => {
  service.enabled = undefined;
  service.enableCallbacks = [];
});

// Those are Default settings but can be overwritten with data from the VIEW
const settings = {
  cookie_name: 'cookie_settings',
  domain: '.ableton.com',
  banner_url: 'https://www.ableton.com/en/cookie-banner/',
  secure: true,
  max_age: 31536000
};

const biscuits = {
  requires_consent: undefined
};

function cookieRegExp(cookieName) {
  return new RegExp('(^|;) ?' + cookieName + '=([^;]*)(;|$)');
}

function hasBannerCookie() {
  return cookieRegExp('cookie_banner').test(document.cookie);
}

function setBannerCookie() {
  document.cookie = `cookie_banner=true;max-age=3600;path=/;domain=${settings.domain}`;
}

function purgeCookieSettings() {
  document.cookie = `${settings.cookie_name}=;max-age=0;path=/;domain=settings.domain`;
}

function storeCookieSettings() {
  const o = {};
  for (const identifier in services) {
    o[identifier] = services[identifier].enabled;
  }
  const v = escape(JSON.stringify(o));
  let cookie = `${settings.cookie_name}=${v};max-age=${settings.max_age};path=/;domain=${settings.domain}`;
  if (settings.secure) {
    cookie += ';secure;samesite=none';
  }
  document.cookie = cookie;
}

function fetchCookieSettings() {
  const m = cookieRegExp(settings.cookie_name).exec(document.cookie);
  if (m) {
    try {
      return JSON.parse(unescape(m[2]));
    } catch {
      purgeCookieSettings();
    }
  }
}

function parseCookieSettings(cookieSettings) {
  const newServices = [];
  for (const [identifier, service] of Object.entries(services)) {
    if (cookieSettings.hasOwnProperty(identifier)) {
      service.enabled = Boolean(cookieSettings[identifier]);
    } else {
      newServices.push(identifier);
    }
  }
  return newServices;
}

function createOverlay(data) {
  document.body.insertAdjacentHTML('beforeend', data);
  const overlay = document.getElementById('biscuits-overlay');

  overlay
    .querySelector('#biscuits-close-button')
    .addEventListener('click', function() {
      setBannerCookie();
      removeOverlay();
    });

  overlay
    .querySelector('#biscuits-accept-button')
    .addEventListener('click', function() {
      for (const identifier in services) {
        services[identifier].enabled = true;
      }

      storeCookieSettings();
      removeOverlay();
      for (const identifier in services) {
        for (const callback of services[identifier].enableCallbacks) {
          // user gave his consent, now run all the collected init functions for the trackers
          callback();
        }
      }
    });
}

function renderOverlay() {
  fetch(settings.banner_url + '?next=' + window.location.href)
    .then(function(response) {
      return response.text();
    })
    .then(function(data) {
      const f = createOverlay.bind(undefined, data);
      if (document.body) {
        f();
      } else {
        document.addEventListener('DOMContentLoaded', f, { once: true });
      }
    });
}

function removeOverlay() {
  document.getElementById('biscuits-overlay').remove();
}

biscuits.configure = function(override) {
  // configure is called inline with override settings from the view
  Object.assign(settings, override);
  // this is a setting cookie that is set when the user makes adjustments to which tracker he wants to use
  // this happens in abl/web/templates/web/cookie-options.html via an request after submiting the settings
  const cookieSettings = fetchCookieSettings();
  if (cookieSettings) {
    const newServices = parseCookieSettings(cookieSettings);
    biscuits.requires_consent = Boolean(newServices.length);
  } else {
    biscuits.requires_consent = !hasBannerCookie();
  }
  if (biscuits.requires_consent) {
    renderOverlay();
  }
};

biscuits.when = function(identifier, enableCallback, disableCallback) {
  // the callbacks that are applied here are basicly the functions that the trackers use to init themself
  // biscuits.when is called at several places where those scripts are included
  // the init functions get collected and then called when the user gives his consent
  const service = services[identifier];
  if (service) {
    // NOTE: When the cookie is not set enable will return undefined, why not set it to false then?
    if (service.enabled) {
      enableCallback.apply(service);
    } else {
      if (disableCallback !== undefined) {
        disableCallback.apply(service);
      }
      service.enableCallbacks.push(enableCallback);
    }
  } else {
    console.warn(identifier);
  }
};

window['biscuits'] = biscuits;
